import React from 'react';
import { createUseStyles } from 'react-jss';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { IconDanger, IconMinusCircle, IconCheckCircle } from '@wds/icons';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  return {
    embeddedContainer: {
      height: theme.util.fullHeight,
      backgroundColor: theme.endpageColors.embeddedNonBranded.backgroundColor,
    },
    textContainer: {
      overflow: 'auto',
    },
  };
});

function EmbeddedNonBrandedHeader({ message, icon }: Props): React.ReactElement {
  const { embeddedContainer, textContainer } = useStyles();
  let Icon;
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (true) {
    case icon === 'closed':
      Icon = (
        <IconDanger
          color="error"
          data-testid="EmbeddedNonBrandedHeader__ClosedIcon"
          size="lg"
          title=""
          aria-hidden="true"
        />
      );
      break;
    case icon === 'taken':
      Icon = (
        <IconMinusCircle
          color="secondary"
          data-testid="EmbeddedNonBrandedHeader__TakenIcon"
          size="lg"
          title=""
          aria-hidden="true"
        />
      );
      break;
    case icon === 'thanks':
      Icon = (
        <IconCheckCircle
          color="primary"
          data-testid="EmbeddedNonBrandedHeader__ThanksIcon"
          size="lg"
          title=""
          aria-hidden="true"
        />
      );
      break;
  }
  return (
    <Box
      className={embeddedContainer}
      display="flex"
      flexAlign="center"
      flexJustify="center"
      flexDirection="column"
      px={6}
      py={6}
    >
      <Box pb={2}>{Icon}</Box>

      <Box px={6} className={textContainer}>
        <Typography
          align="center"
          color="darkMuted"
          component="div"
          variant="body"
          data-testid="EmbeddedNonBrandedHeader__Text"
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export type Props = {
  message: string;
  icon: 'closed' | 'taken' | 'thanks';
};

export default EmbeddedNonBrandedHeader;
