import React from 'react';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { IconXCircle } from '@wds/icons';

import useStyles from './useStyles';

export type Props = {
  copy: string;
};

const NonBrandedEndpageHeader: React.FC<Props> = ({ copy }) => {
  const classes = useStyles();

  return (
    <Box p={6} className={classes.header}>
      <Typography align="center" component="section" variant="sectionTitle">
        <span className={classes.icon}>
          <IconXCircle data-testid="NonBrandedEndpageHeader__Icon" title="Close" />
        </span>
        <span data-testid="NonBrandedEndpageHeader__Text" className={classes.boldText}>
          {copy}
        </span>
      </Typography>
    </Box>
  );
};

export default NonBrandedEndpageHeader;
