import React, { useContext } from 'react';

import { defineMessages, t } from '@sm/intl';
import { StaticContext } from '@sm/webassets/StaticContext';
import { createURL } from '@sm/utils';
import { createUseStyles } from 'react-jss';
import { LogoWithText } from '@sm/webassets/Logos';
import { generateMetricsAttribute } from '@sm/metrics';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { Button } from '@wds/button';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';

const COPY = defineMessages({
  BRANDED_EMBEDDED_POWERED_BY: {
    id: 'BrandedEmbeddedHeader.BrandedEmbeddedHeader.PoweredBy',
    defaultMessage: 'Powered by',
    description:
      '[Type: Label][Vis: med] - This figure of speech is used to introduce and display the SurveyMonkey Logo',
  },
  BRANDED_EMBEDDED_CTA: {
    id: 'BrandedEmbeddedHeader.BrandedEmbeddedHeader.EmbeddedCTAText',
    defaultMessage: 'Sign up free',
    description:
      '[Type: Label][Vis: med] - This is the phrase used inside a button that directs views to a signup page',
  },
  BRANDED_EMBEDDED_CTA_TITLE: {
    id: 'BrandedEmbeddedHeader.BrandedEmbeddedHeader.EmbeddedCTATitle',
    defaultMessage: 'Create surveys. Get answers.',
    description:
      '[Type: Label][Vis: low] - This text is here for accessibility reasons, it is usually hidden from users.',
  },
  LOGO_ARIA_LABEL: {
    id: 'BrandedEmbeddedHeader.BrandedEmbeddedHeader.LogoAriaLabel',
    defaultMessage: 'SurveyMonkey home',
    description: '[Type: Header][Vis: med] - The SurveyMonkey Logo shown at the top of the page',
  },
});

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  return {
    embeddedContainer: {
      overflowY: 'auto',
      width: '100%',
    },
    embeddedWrapper: {
      height: theme.util.fullHeight,
      minWidth: 300,
    },
    headerContainer: {
      maxWidth: 300,
    },
    logoWithText: {
      height: 23,
    },
    ctaWrapper: {
      backgroundColor: theme.brandColors.neutral.slate,
      width: 280,
      minHeight: 102,
      borderRadius: 5,
    },
  };
});

function BrandedEmbeddedHeader({ message, utSource }: Props): React.ReactElement {
  const classes = useStyles();
  const utSource2 = 'Embedded';
  const utSource3 = 'BrandedEmbeddedHeader';
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);
  return (
    <Box
      className={classes.embeddedWrapper}
      display="flex"
      flexAlign="center"
      flexJustify="center"
      flexDirection="column"
    >
      <Box className={classes.embeddedContainer} display="flex" flexAlign="center" flexDirection="column" py={6}>
        <Box className={classes.headerContainer}>
          <Typography variant="body" align="center" data-testid="BrandedEmbeddedHeader__Text">
            {message}
          </Typography>
        </Box>
        <Box pt={4}>
          <Typography variant="bodySm" color="darkMuted">
            {t(COPY.BRANDED_EMBEDDED_POWERED_BY)}
          </Typography>
        </Box>
        <Box p={2}>
          <a
            aria-label={t(COPY.LOGO_ARIA_LABEL)}
            data-testid="BrandedEmbeddedHeader__Goldie"
            href={createURL('/', {
              ut_source: utSource,
              ut_source2: utSource2,
              ut_source3: utSource3,
              ut_ctatext: 'LogoWithText',
            })}
            data-sm-metrics={generateMetricsAttribute({
              data: {
                amplitudeEvent: 'endpage user interaction',
                itemSelected: 'logo',
                templateType,
                surveyId,
                collectorId,
                respondentId,
                log: {
                  template: `${utSource}-${utSource2}-${utSource3}-LogoWithText`,
                },
              },
            })}
          >
            <LogoWithText className={classes.logoWithText} />
          </a>
        </Box>
        <Box
          className={classes.ctaWrapper}
          display="flex"
          flexAlign="center"
          flexJustify="center"
          flexDirection="column"
          p={2}
        >
          <Typography color="light" component="p" align="center">
            {t(COPY.BRANDED_EMBEDDED_CTA_TITLE)}
          </Typography>
          <Box mb={1} />
          <Button
            color="alt"
            size="sm"
            title={t(COPY.BRANDED_EMBEDDED_CTA)}
            href={createURL('/user/sign-up/', {
              ut_source: utSource,
              ut_source2: utSource2,
              ut_source3: utSource3,
              ut_ctatext: COPY.BRANDED_EMBEDDED_CTA.defaultMessage,
            })}
            data-sm-metrics={generateMetricsAttribute({
              data: {
                amplitudeEvent: 'endpage user interaction',
                itemSelected: 'sign-up primary',
                templateType,
                surveyId,
                collectorId,
                respondentId,
                log: {
                  message: `${utSource}-${utSource2}-${utSource3}-${COPY.BRANDED_EMBEDDED_CTA.defaultMessage}`,
                },
              },
            })}
            target="_blank"
          >
            {t(COPY.BRANDED_EMBEDDED_CTA)}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export type Props = {
  message: string;
  utSource: string;
};

export default BrandedEmbeddedHeader;
