import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

const useStyles = createSurveyStyles({
  header: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '42vh',
    width: '100%',
  },

  icon: {
    marginRight: '0.5rem',
    '&> svg': {
      width: 40,
      height: 40,
    },
  },

  boldText: {
    fontWeight: 500,
  },
});

export default useStyles;
