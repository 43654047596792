import React from 'react';
import { defineMessages, t } from '@sm/intl';

import BrandedEmbeddedHeader from '~app/components/BrandedEmbeddedHeader';

const COPY = defineMessages({
  BRANDED_EMBEDDED_COPY: {
    id: 'SurveyClosed.BrandedEmbeddedSurveyClosed.DefaultMessage',
    defaultMessage: 'This survey is closed.',
    description: '[Type: Header][Vis: high] - Informs the viewer that this survey has been closed',
  },
});

export type Props = {
  message?: string;
};

const BrandedEmbeddedSurveyClosed = ({ message = t(COPY.BRANDED_EMBEDDED_COPY) }: Props): React.ReactElement => (
  <BrandedEmbeddedHeader message={message} utSource="survey-closed" />
);

export default BrandedEmbeddedSurveyClosed;
