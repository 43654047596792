import React from 'react';
import { defineMessages, t } from '@sm/intl';
import { createURL } from '@sm/utils';
import { IconXCircle } from '@wds/icons';

import EndpageHeader from '~app/components/EndpageHeader';
import RebrandEpFormCore from '~app/components/RebrandEpFormCore';
import { ProductsCalloutListItem } from '~app/components/ProductsCalloutList/ProductsCalloutList';

import PerksIconWhite from '~static/images/icons/perks-icon-white.svg';
import MsgIconWhite from '~static/images/icons/msg-icon-white.svg';
import { getSrc } from '~app/helpers/assetLoading';

const COPY = defineMessages({
  ENDPAGE_HEADER_COPY: {
    id: 'SurveyClosed.EUBrandedSurveyClosed.EndpageHeaderCopy',
    defaultMessage: 'This survey is currently closed.',
    description: '[Type: Header][Vis: med] - Informs the viewer that this survey has been closed',
  },
  PRODUCT_CALLOUT_PERKS_HEADING: {
    id: 'SurveyClosed.EUBrandedSurveyClosed.ProductCalloutPerksHeader',
    defaultMessage: 'Is your company missing key perks?',
    description:
      '[Type: Header][Vis: med] - Tagline to help entice users to views click the cta below. The CTA leads to another signup page with minimal information.',
  },
  PRODUCT_CALLOUT_PERKS_BODY: {
    id: 'SurveyClosed.EUBrandedSurveyClosed.ProductCalloutPerksBody',
    defaultMessage:
      'People love their work perks. Send a survey to find out if you’re offering the incentives employees and potential candidates want.',
    description: '[Type: Paragraph][Vis: med] - Gives context to the above Tagline',
  },
  PRODUCT_CALLOUT_PERKS_CTA: {
    id: 'SurveyClosed.EUBrandedSurveyClosed.ProductCalloutPerksCTA',
    defaultMessage: 'Find out',
    description: '[Type: Button][Vis: med] - The text for a button which leads users to another sign up page',
  },
  PRODUCT_CALLOUT_COLLABORATION_HEADING: {
    id: 'SurveyClosed.EUBrandedSurveyClosed.ProductCalloutCollaborationEUHeader',
    defaultMessage: 'Are your customers satisfied?',
    description: '[Type: Header][Vis: med] - Tag line to help user learn more about SurveyMonkey Collaboration',
  },
  PRODUCT_CALLOUT_COLLABORATION_BODY: {
    id: 'SurveyClosed.EUBrandedSurveyClosed.ProductCalloutCollaborationEUBody',
    defaultMessage: '83% of businesses who describe themselves as “successful” actively measure customer satisfaction.',
    description: '[Type: Paragraph][Vis: med] - Expands and reiterate the above header line.',
  },
  PRODUCT_CALLOUT_COLLABORATION_CTA: {
    id: 'SurveyClosed.EUBrandedSurveyClosed.ProductCalloutCollaborationEUCTA',
    defaultMessage: 'Get started',
    description:
      "[Type: Button][Vis: med] - The button text which leads users to a marketing page to learn more about SurveyMonkey' premium plans",
  },
});

export type Props = {
  message?: string;
};

const EUBrandedSurveyClosed = ({ message = t(COPY.ENDPAGE_HEADER_COPY) }: Props): React.ReactElement => {
  const utSource = 'survey-closed';
  const utSource2 = 'RebrandEpFormIntl';
  const utSource3 = 'ProductCalloutItem';

  const CALLOUT_ITEM_SIGN_UP: ProductsCalloutListItem = {
    icon: getSrc(PerksIconWhite),
    heading: t(COPY.PRODUCT_CALLOUT_PERKS_HEADING),
    copy: t(COPY.PRODUCT_CALLOUT_PERKS_BODY),
    href: createURL('/user/sign-up/', {
      ut_source: utSource,
      ut_source2: utSource2,
      ut_source3: utSource3,
      ut_ctatext: COPY.PRODUCT_CALLOUT_PERKS_CTA.defaultMessage,
    }),
    ctaText: COPY.PRODUCT_CALLOUT_PERKS_CTA,
    ctaUsage: 'sign-up secondary',
    ctaColor: 'sabaeus',
    name: 'perks',
  };

  const CALLOUT_ITEM_COLLABORATION: ProductsCalloutListItem = {
    icon: getSrc(MsgIconWhite),
    heading: t(COPY.PRODUCT_CALLOUT_COLLABORATION_HEADING),
    copy: t(COPY.PRODUCT_CALLOUT_COLLABORATION_BODY),
    href: createURL('/mp/collaboration/', {
      ut_source: utSource,
      ut_source2: utSource2,
      ut_source3: utSource3,
      ut_ctatext: COPY.PRODUCT_CALLOUT_COLLABORATION_CTA.defaultMessage,
    }),
    ctaColor: 'concord',
    ctaText: COPY.PRODUCT_CALLOUT_COLLABORATION_CTA,
    ctaUsage: 'collaboration',
    name: 'collaboration',
  };

  return (
    <>
      <EndpageHeader
        copy={message}
        headerColor="sabaeus"
        headerType="static"
        icon={<IconXCircle aria-hidden="true" title="" />}
      />
      <RebrandEpFormCore
        utSource={utSource}
        utSource2="RebrandEpForm"
        calloutItem1={CALLOUT_ITEM_SIGN_UP}
        calloutItem2={CALLOUT_ITEM_COLLABORATION}
        hideCTAs
      />
    </>
  );
};

export default EUBrandedSurveyClosed;
