import React from 'react';
import { defineMessages, t } from '@sm/intl';

import NonBrandedEndpageHeader from '~app/components/NonBrandedEndpageHeader';

const COPY = defineMessages({
  NONBRANDED_ENDPAGEHEADER_COPY: {
    id: 'SurveyClosed.NonBrandedSurveyClosed.NonBrandedEndpageHeader',
    defaultMessage: 'This survey is closed. Please contact the author of this survey for further assistance.',
    description: '[Type: Header][Vis: high] - Informs the viewer that this survey has been closed',
  },
});

export type Props = {
  message?: string;
};

const NonBrandedSurveyClosed = ({ message = t(COPY.NONBRANDED_ENDPAGEHEADER_COPY) }: Props): React.ReactElement => (
  <NonBrandedEndpageHeader copy={message} />
);

export default NonBrandedSurveyClosed;
