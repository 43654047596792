import React from 'react';
import Script from 'next/script';

export default function OneTrustFooterScript(): React.ReactElement {
  return (
    <Script id="modify-footer-button" type="text/javascript" strategy="lazyOnload">
      {`
          const privacyButton = document.getElementsByClassName("ot-sdk-show-settings");
          privacyButton[0]?.removeAttribute("href");
          privacyButton[0]?.setAttribute("style", "cursor:pointer;");
      `}
    </Script>
  );
}
